import { useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { IonCard, IonRow, IonCol, IonButton } from '@ionic/react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import Overlay from '../../../../components/Overlays/Overlay';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import SelectStyled from '../../../../components/UI/SelectStyled';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import Form from '../../../../components/Forms/Form';
import { db2Form } from '../../../../api/forms';
import SecuritySettingsTabs from './SecuritySettingsTabs';
import SecuritySettingsApps from './SecuritySettingsApps';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { ColDef } from '@ag-grid-community/core';

const SecuritySettingsIndex: React.FC<RouteIndexComponent> = ({
	uid,
	routeTitle,
	permissionTo,
}) => {
	const [data, setData] = useState<any>([]);
	const [showOverlay, setShowOverlay] = useState<boolean>(false);
	const [overlayContent, setOverlayContent] = useState<any>(null);
	const [selectedRow, setSelectedRow] = useState<any>(null);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [overlayFooterContent, setOverlayFooterContent] = useState<any>(null);
	const [overlayTitle, setOverlayTitle] = useState<any>(null);

	const getRoleOptions = (roles: any) => {
		return roles.map((r: any) => {
			return {
				value: r.id,
				label: r.name,
				colour: r.colour,
			};
		});
	};

	const getSelectedRole = (roles: any) => {
		const selectedRole = roles.filter((role: any) => role.selected === true);
		return selectedRole[0] && selectedRole[0].id ? selectedRole[0].id : null;
	};

	const columns: Array<ColDef> = [
		{
			headerName: 'Name',
			field: 'last_name',
			sortable: true,
			flex: 1,
			sort: 'asc',
			cellRenderer: (params: any) => {
				return `${params.data.first_name} ${params.data.last_name}`;
			},
		},
		{
			field: 'securitySetting',
			sortable: false,
			cellRenderer: (params: any) => {
				// Get all possible roles from the row
				const roleOptions = getRoleOptions(params.data.roles);

				// Determine the current role
				const selectedRole = getSelectedRole(params.data.roles);

				if (permissionTo('update')) {
					return (
						<SelectStyled
							className='in-cell'
							options={roleOptions}
							defaultValue={roleOptions.filter(
								(roleOption: any) => roleOption.value === selectedRole
							)}
							onChange={(option: any) => handleGridSave(option, params.data.id)}
							menuPortalTarget={document.body}
						/>
					);
				} else {
					let selectedRoleLabel: any = roleOptions.filter(
						(roleOption: any) => roleOption.value === selectedRole
					);
					if (selectedRoleLabel && selectedRoleLabel.length > 0) {
						return selectedRoleLabel[0]?.label ?? 'Not found';
					} else {
						return 'Not selected';
					}
				}
			},
		},
		{
			field: 'email',
			sortable: true,
			flex: 1,
		},
		{
			field: 'devices',
			sortable: false,
			cellRenderer: (params: any) => {
				return `${params.data.devices_count} registered device${
					params.data.devices_count !== 1 ? 's' : ''
				}`;
			},
		},
	];

	const triggerOverlay = (params: any) => {
		const endpointID = params.id;

		// Get all possible roles from the row
		const roleOptions = getRoleOptions(params.roles);

		// Determine the current role
		const selectedRole = getSelectedRole(params.roles);

		let formData: Array<any> = [
			{
				title: 'First Name',
				type: 'text',
				db: ['first_name'],
			},
			{
				title: 'Last Name',
				type: 'text',
				db: ['last_name'],
			},
			{
				title: 'Security Setting',
				type: 'dropdown',
				db: ['role_id'],
				values: roleOptions,
				defaultValue: roleOptions.filter((roleOptions: any) => roleOptions.value === selectedRole),
				onChangeCallback: (option: any) => {
					updateGridSelect(option, params.id);
				},
			},
			{
				title: 'Email Address',
				type: 'email',
				db: ['email'],
			},
			{
				title: 'Password',
				type: 'password',
				db: ['password'],
			},
			{
				title: 'Two Factor Approved Devices',
				type: 'itemList',
			},
		];

		formData = db2Form(formData, params);

		setOverlayContent(
			<Form
				array={formData}
				permissionTo={permissionTo}
				forceEdit={permissionTo('update')}
				noButton={true}
				endpoint={'/api/utilities/security_settings/'}
				endpointID={endpointID}
				onChangeCallback={updateGridData}
			/>
		);

		setOverlayTitle('Security Settings - User Details');
		setShowOverlay(true);
	};

	const updateGridData = (payload: any) => {
		const dataUpdated = data.map((arr: any) => {
			// Find the correct row
			if (arr.id === payload.endpointID) {
				// Update the row with the payload
				arr.first_name = payload.data.filter((p: any) => p.db && p.db[0] === 'first_name')[0].value;
				arr.last_name = payload.data.filter((p: any) => p.db && p.db[0] === 'last_name')[0].value;
				arr.email = payload.data.filter((p: any) => p.db && p.db[0] === 'email')[0].value;
			}
			return arr;
		});

		setData(dataUpdated);
	};

	const updateGridSelect = (option: any, rowID: string, roles: any = []) => {
		const dataUpdated: Array<any> = data.map((arr: any) => {
			if (arr.id === rowID) {
				// Check if we need to inject a new list of roles
				if (roles.length > 0) {
					arr.roles = roles.map((r: any) => {
						return {
							id: r.value,
							name: r.label,
							colour: r.colour,
							selected: false,
						};
					});
				}

				// Set the relevant role as selected
				arr.roles.map((role: any) => {
					role.selected = role.id === option.value;
					return role;
				});
			}
			return arr;
		});

		setData(dataUpdated);
	};

	const hideOverlay = () => {
		setShowOverlay(false);
		setOverlayContent(null);
		setOverlayFooterContent(null);
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'securitySetting') {
			params.node.setSelected(true);
			setSelectedRow(params.data);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'securitySetting') {
			handleCellClicked(params);
			handleEdit();
		}
	};

	const handleEdit = () => {
		setOverlayFooterContent({ buttons: [{ type: 'close' }] });
		triggerOverlay(selectedRow);
	};

	const handleCustomiseSecuritySettings = () => {
		let roleID = selectedRow.roles.filter((r: any) => r.selected === true);
		if (roleID && roleID[0] && roleID[0].id) roleID = roleID[0].id;

		setOverlayContent(
			<SecuritySettingsTabs
				mode='worker'
				roleID={roleID}
				workerID={selectedRow.id}
				permissionTo={permissionTo}
				hideOverlay={hideOverlay}
				handleUpdate={updateGridSelect}
				setOverlayFooterContent={setOverlayFooterContent}
			/>
		);

		setOverlayTitle(
			`Customise Security Settings - ${selectedRow.first_name} ${selectedRow.last_name}`
		);
		setShowOverlay(true);
	};

	const handleCustomiseAppList = () => {
		let roleID = selectedRow.roles.filter((r: any) => r.selected === true);
		if (roleID && roleID[0] && roleID[0].id) roleID = roleID[0].id;

		setOverlayContent(
			<SecuritySettingsApps
				mode='worker'
				roleID={roleID}
				workerID={selectedRow.id}
				permissionTo={permissionTo}
				hideOverlay={hideOverlay}
				handleUpdate={updateGridSelect}
				setOverlayFooterContent={setOverlayFooterContent}
			/>
		);

		setOverlayTitle(
			`Customise App Whitelist - ${selectedRow.first_name} ${selectedRow.last_name}`
		);
		setShowOverlay(true);
	};

	const handleGridSave = async (option: any, rowID: string) => {
		const endpointID = rowID;
		const roleID = option.value;

		const toastID = toast.loading('Please wait...');

		axios
			.put(`/api/utilities/security_settings/${endpointID}`, { role_id: roleID })
			.then((res) => {
				showToast('saved', null, toastID);

				// Update the grid data
				updateGridSelect(option, rowID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const loadGridData = () => {
		axios.get('/api/utilities/security_settings').then((d: any) => {
			setData(d.data);
			setGridLoading(false);
		});
	};

	// Security properties for the data table
	let dataTableSecurityProps: any = {};
	if (permissionTo('update')) {
		dataTableSecurityProps = {
			cellClickedFunction: handleCellClicked,
			cellDoubleClickedFunction: handleCellDoubleClicked,
		};
	}

	// Initial grid data load
	useEffect(() => {
		loadGridData();
	}, []);

	return (
		<>
			{showOverlay && (
				<Overlay
					hideFunction={() => hideOverlay()}
					content={overlayContent}
					title={overlayTitle}
					footerContent={overlayFooterContent}
				/>
			)}
			<TitleBar title={routeTitle} />
			<IonCard className='table-card filter-data-table full-height-card'>
				<DataGrid
					title={routeTitle}
					cols={columns}
					data={data}
					autoSize={true}
					{...dataTableSecurityProps}
					extraFooter={
						<IonRow>
							<IonCol size={'4'} className='ps-0'>
								{permissionTo('create') && (
									<NavLink to='/utilities/workers/security_settings/manage'>
										<IonButton color='success'>Manage Security Settings</IonButton>
									</NavLink>
								)}
							</IonCol>
							<IonCol size={'8'} className='text-right pe-0'>
								{permissionTo('update') && (
									<>
										<IonButton
											onClick={handleCustomiseAppList}
											color='secondary'
											disabled={!selectedRow || gridLoading}
										>
											Customise App List
										</IonButton>
										<IonButton
											onClick={handleCustomiseSecuritySettings}
											color='secondary'
											disabled={!selectedRow || gridLoading}
										>
											Customise Security Settings
										</IonButton>
										<IonButton
											onClick={handleEdit}
											color='primary'
											disabled={!selectedRow || gridLoading}
										>
											Edit Item
										</IonButton>
									</>
								)}
							</IonCol>
						</IonRow>
					}
				/>
			</IonCard>
		</>
	);
};

export default SecuritySettingsIndex;

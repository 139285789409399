import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import DataGrid from '../../../components/DataGrid/DataGrid';
import { TimesheetsContext } from '../TimesheetsProvider';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { IonButton } from '@ionic/react';
import TimesheetHeader from './components/TimesheetHeader';
import WeekendingLabel from './components/WeekendingLabel';
import { moduleContext } from '../../../contexts/ModuleContext';
import { DateTime } from 'luxon';
import { getYearSelectOptions } from '../../../lib/functions';
import { authContext } from '../../../contexts/AuthContext';
import Loading from '../../../components/UI/Loading';
import { ColDef, ICellRendererParams } from '@ag-grid-community/core';
import { dateFormatterFullOrdinal } from '../../../helpers/dateFormatter';
import AllocationModal from './modals/AllocationModal';
import { AllocationModalProps, PaymentMethod } from '../timesheets-types';

type Props = {
	pageId: string;
	permissionTo: Function;
};

const DailyHourlyExceptionReport = (props: Props) => {
	const { state, dispatch } = useContext(TimesheetsContext);
	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const prevShowMyTeam: any = useRef(null);
	const prevWeekending: any = useRef(state.weekEnding);
	const [yearChange, setYearChange] = useState<boolean>(false);
	const [weekEndingsLoading, setWeekEndingsLoading] = useState<boolean>(true);
	const [weekEndingOptions, setWeekEndingOptions] = useState<Array<any>>([]);
	const [data, setData] = useState<Array<any>>([]);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [workerLoading, setWorkerLoading] = useState<boolean>(true);
	const [worker, setWorker] = useState<any>({});
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [dayId, setDayId] = useState<string | undefined>(undefined);
	const [allocationDate, setAllocationDate] = useState<DateTime>(DateTime.now());
	const [workerName, setWorkerName] = useState<string | undefined>(undefined);
	const DEFAULT_DATE_FORMAT: string = 'yyyy-MM-dd';
	const columns: Array<ColDef> = [
		{
			field: 'date',
			headerName: 'Date',
			rowGroup: true,
			hide: true,
		},
		{
			field: 'worker',
			headerName: 'Worker',
			sortable: false,
			resizable: false,
			flex: 1,
		},
		{
			field: 'job_title',
			headerName: 'Job Title',
			sortable: false,
			resizable: false,
			flex: 1,
		},
		{
			field: 'exceptions',
			headerName: 'Exceptions',
			sortable: false,
			resizable: false,
			autoHeight: true,
			wrapText: true,
			cellClass: 'col-has-exceptions',
			cellRenderer: (params: any) => {
				const exceptionsArray: Array<any> = params.value.map((item: any, index: number) => {
					return <li key={index}>{item}</li>;
				});

				if (params.value.length > 0) {
					return <ul>{exceptionsArray}</ul>;
				}
			},
			valueFormatter: (params: any) => params.value,
			flex: 1,
		},
	];

	// Modals
	const [allocationModal, setAllocationModal] = useState<AllocationModalProps>({
		isOpen: false,
		allocationData: null,
	});

	useEffect(() => {
		setIsLoading(workerLoading || gridLoading || yearChange);
	}, [workerLoading, gridLoading, yearChange]);

	useEffect(() => {
		loadWorker(authCtx.user.worker_id);
		loadWeekEndings();
	}, []);

	useEffect(() => {
		if (
			(state.showMyTeam !== prevShowMyTeam.current ||
				state.weekEnding.toFormat(DEFAULT_DATE_FORMAT) !==
					prevWeekending.current.toFormat(DEFAULT_DATE_FORMAT)) &&
			gridReady === true
		) {
			prevShowMyTeam.current = state.showMyTeam;
			prevWeekending.current = state.weekEnding;
			loadGridData();
		}
	}, [state.showMyTeam, state.weekEnding, gridReady]);

	const loadWorker = (workerId: string) => {
		setWorkerLoading(true);

		moduleCtx
			.getWorker(workerId)
			.then((res: any) => {
				setWorker(res);
			})
			.finally(() => {
				setWorkerLoading(false);
			});
	};

	const loadGridData = () => {
		setGridLoading(true);
		setDayId(undefined);
		setAllocationDate(DateTime.now());
		setWorkerName(undefined);
		setData([]);

		let payload: any = {
			week_ending: state.weekEnding.toFormat('yyyy-MM-dd'),
			payment_method: [PaymentMethod.DAILY, PaymentMethod.HOURLY],
			my_team: state.showMyTeam,
		};

		axios
			.post('/api/timesheets/timesheets_and_allocations/exceptions_report', payload)
			.then((res: any) => {
				setData(res.data);
			})
			.catch((err: any) => {
				if (err && err.code && err.code !== 'ERR_CANCELED') {
					showToast('error');
				}
			})
			.finally(() => {
				setGridLoading(false);
				setYearChange(false);
			});
	};

	const loadWeekEndings = (theYear?: number) => {
		setWeekEndingsLoading(true);

		axios
			.post('/api/timesheets/timesheets_and_allocations/weekending', {
				year: theYear ? theYear : state.year,
			})
			.then((res: any) => {
				setWeekEndingOptions(
					res.data.map((item: any) => ({
						label: <WeekendingLabel weekending={item.weekending} approved={item.approved} />,
						value: item.weekending,
					}))
				);
			})
			.catch(() => showToast('error'))
			.finally(() => setWeekEndingsLoading(false));
	};

	const handleCellClicked = (params: any) => {
		params.node.setSelected(true);
		setDayId(params.data.id);
		setAllocationDate(DateTime.fromISO(params.data.date));
		setWorkerName(params.data.worker);
	};

	const handleCellDoubleClicked = (params: any) => {
		handleCellClicked(params);
		handleViewAllocation();
	};

	const handleViewAllocation = () => {
		setAllocationModal({
			isOpen: true,
			allocationData: {
				type: 'day',
				event: {
					id: dayId,
					date: allocationDate,
				},
				workerData: { name: workerName },
			},
		});
	};

	const allocationModalOnClose = (reloadGrid: boolean = false) => {
		setAllocationModal({ ...allocationModal, isOpen: false });
		if (reloadGrid === true) loadGridData();
	};

	const groupRowRendererParams = useMemo(() => {
		return {
			innerRenderer: (props: ICellRendererParams) => {
				return (
					<>
						<strong>Timesheet: </strong>
						{dateFormatterFullOrdinal(props.value)}
					</>
				);
			},
			suppressCount: true,
		};
	}, []);

	return (
		<div className={`${props.pageId}`}>
			{isLoading && <Loading overlay={true} />}
			<TimesheetHeader
				setIsLoading={setIsLoading}
				setYearChange={setYearChange}
				loadWeekEndings={loadWeekEndings}
				weekEndingOptions={weekEndingOptions}
				yearSelectOptions={getYearSelectOptions({ prefix: 'Year' })}
				approvesAllTimesheets={
					worker && worker.hasOwnProperty('key_details')
						? worker.key_details.approves_all_timesheets
						: false
				}
				DEFAULT_DATE_FORMAT={DEFAULT_DATE_FORMAT}
			/>

			<DataGrid
				onGridReady={() => {
					setGridReady(true);
				}}
				title={null}
				cols={columns}
				data={data}
				groupDisplayType={'groupRows'}
				groupDefaultExpanded={1}
				groupRowRendererParams={groupRowRendererParams}
				useSearch={false}
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				rowCursorPointer={props.permissionTo('update')}
				extraFooter={
					<div className='row-lower-controls'>
						<IonButton
							color='primary'
							disabled={dayId === undefined}
							onClick={() => handleViewAllocation()}
						>
							View Record
						</IonButton>
					</div>
				}
			/>

			<AllocationModal
				isOpen={allocationModal.isOpen}
				allocationData={allocationModal.allocationData}
				onClose={allocationModalOnClose}
				permissionTo={props.permissionTo}
				loadWeekEndings={loadWeekEndings}
			/>
		</div>
	);
};

export default DailyHourlyExceptionReport;

import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIonAlert, IonRow, IonCol, IonButton } from '@ionic/react';
import axios from '../../../../lib/axios';
import { DateTime } from 'luxon';
import { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { authContext } from '../../../../contexts/AuthContext';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import { showToast } from '../../../../lib/toast';
import ListFilterPaymentRegister from '../components/ListFilterPaymentRegister';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { getTimezone, underscoredToUppercasedWords } from '../../../../lib/functions';
import { ColDef } from '@ag-grid-community/core';

const PaymentRegisterList: React.FC = () => {
	const authCtx: any = useContext(authContext);
	const defaultFilter = { label: 'All', payload: { status: ['paid', 'part_paid'] } };
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [tableTitle, setTableTitle] = useState<string>('');
	const [curMonth, setCurMonth] = useState<number>(Number(DateTime.now().toFormat('MM')));
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const [curFilterPayload, setCurFilterPayload] = useState<any>(null);
	const [paymentId, setPaymentId] = useState<string | null>(null);
	const [invoiceId, setInvoiceId] = useState<string | null>(null);
	const [jobId, setJobId] = useState<string | null>(null);
	const [presentAlert] = useIonAlert();
	const permissionTo = usePermissionTo('jobs.invoices_list');
	let history = useHistory();

	const loadGridData = (month: number, year: number, payload: any = null) => {
		setGridLoading(true);
		setJobId(null);
		setInvoiceId(null);
		setPaymentId(null);
		setData([]);

		if (payload === null) payload = curFilterPayload;

		axios
			.post('/api/jobs/payments_list', {
				month: month,
				year: year,
				...getTimezone(),
				...payload,
			})
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);

				totalRow(res.data);
				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleListFilterChange = (label: string, payload: any) => {
		setCurFilterPayload(payload);
		loadGridData(curMonth, curYear, payload);
		setTableTitle(`Payments Received: ${label}`);
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'delete' && !params.rowPinned) {
			params.node.setSelected(true);
			setPaymentId(params.data.id);
			setInvoiceId(params.data.invoice_id);
			setJobId(params.data.job_id);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'delete' && !params.rowPinned) {
			handleCellClicked(params);
			handleEditInvoice();
		}
	};

	const handleEditInvoice = () => {
		if (permissionTo('update', 'jobs.invoices_list.payment_register')) {
			const path = `/jobs/invoices_list/payment_register/${invoiceId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const handleGoToJob = () => {
		if (permissionTo('update', 'jobs.jobs_list.job_card')) {
			const path = `/jobs/jobs_list/job_card/${jobId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const handleDelete = (id: string) => {
		if (permissionTo('delete')) {
			presentAlert({
				header: 'Delete Payment',
				message: 'Are you sure you want to delete this payment?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleDeleteGo(id);
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		setJobId(null);
		setInvoiceId(null);
		setPaymentId(null);

		axios
			.delete(`/api/jobs/payments_list/${id}`)
			.then(() => {
				loadGridData(curMonth, curYear, curFilterPayload);
				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const columns: Array<ColDef> = [
		{
			field: 'number',
			headerName: 'Invoice Number',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'job_name',
			headerName: 'Job Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'issue_date',
			headerName: 'Issue Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
			sort: 'desc',
		},
		{
			field: 'due_date',
			headerName: 'Due Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'client_name',
			headerName: 'Client Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'date_paid',
			headerName: 'Date Paid',
			valueFormatter: (params: any) => {
				if (!String(params.value).includes('Totals')) {
					return dateFormatter(params);
				}
				return '';
			},
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'value',
			headerName: `Value Gross (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value) {
					return params.data.value.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'value_remaining',
			headerName: `Value Remaining (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value_remaining) {
					return params.data.value_remaining.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 200,
			suppressSizeToFit: true,
		},
		{
			field: 'status',
			headerName: 'Status',
			sortable: true,
			resizable: true,
			flex: 1,
			cellRenderer: (params: any) => {
				const statusWord = params.getValue();
				if (statusWord) {
					return underscoredToUppercasedWords(statusWord);
				}
			},
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') &&
					params.getValue() !== false && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const calcTotalCols = ['value'];
	const totalRow = function (rowdata: any) {
		let result: any = [{}];

		// Calculate total columns
		calcTotalCols.forEach(function (params) {
			result[0][params] = 0;
			rowdata.forEach(function (line: any) {
				result[0][params] += line[params];
			});
		});

		// Clean-up row output
		result[0]['issue_date'] = false;
		result[0]['due_date'] = false;
		result[0]['date_paid'] = `Totals (${authCtx.tenant.currency_symbol}):`;
		result[0]['delete'] = false;

		return result;
	};

	return (
		<>
			<DataGrid
				title={tableTitle}
				cols={columns}
				data={data}
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				rowCursorPointer={permissionTo('update')}
				filterButtons={true}
				filterOptions={{
					displayCurrentMonth: loadGridData,
				}}
				totalsRow={totalRow}
				extraFooter={
					<IonRow>
						<IonCol size='8' className='row-lower-controls-left ps-0'>
							<ListFilterPaymentRegister
								defaultSelected={defaultFilter}
								onChange={handleListFilterChange}
							/>
						</IonCol>
						<IonCol size='4' className='row-lower-controls-right pe-0'>
							<div>
								<IonButton
									color='secondary'
									disabled={jobId === null}
									onClick={() => handleGoToJob()}
								>
									Go To Job
								</IonButton>
								<IonButton
									color='primary'
									disabled={invoiceId === null}
									onClick={() => handleEditInvoice()}
								>
									Edit Invoice
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				}
			/>
		</>
	);
};

export default PaymentRegisterList;

import { useContext, useEffect, useRef, useState } from 'react';
import DataGrid from '../../../../../components/DataGrid/DataGrid';
import { dateFormatter } from '../../../../../helpers/dateFormatter';
import axios from '../../../../../lib/axios';
import { IonButton, IonCol, IonRow } from '@ionic/react';
import SelectStyled from '../../../../../components/UI/SelectStyled';
import { getTimezone } from '../../../../../lib/functions';
import { moduleContext } from '../../../../../contexts/ModuleContext';
import { DateTime } from 'luxon';
import { currencyFormatter } from '../../../../../helpers/currencyFormatter';
import { showToast } from '../../../../../lib/toast';
import { ColDef } from '@ag-grid-community/core';

type Props = {
	jobId: string;
};

const AllocationHistory: React.FC<Props> = (props: Props) => {
	const gridRef: any = useRef<any>();
	const workerFilterRef: any = useRef<any>();
	const moduleCtx = useContext<any>(moduleContext);
	const [curMonth, setCurMonth] = useState<number>(Number(DateTime.now().toFormat('MM')));
	const [curYear, setCurYear] = useState<number>(Number(DateTime.now().toFormat('yyyy')));
	const [gridReady, setGridReady] = useState<boolean>(false);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [data, setData] = useState<Array<any>>([]);
	const [workers, setWorkers] = useState<Array<any>>([]);
	const [workerFilter, setWorkerFilter] = useState<any>(null);
	const columns: Array<ColDef> = [
		{ headerName: 'Date', field: 'date', valueFormatter: dateFormatter, flex: 1, sort: 'desc' },
		{ headerName: 'Worker Name', field: 'worker_name', flex: 1 },
		{ headerName: 'Job Skill', field: 'job_skill', flex: 1 },
		{
			headerName: 'Allocation %',
			field: 'allocation_pct',
			type: 'rightAligned',
			valueFormatter: (params: any) => params.value + '%',
			flex: 1,
		},
		{ headerName: 'Hours', field: 'hours', type: 'rightAligned', flex: 1 },
		{
			headerName: 'Cost to Job £',
			field: 'job_cost',
			type: 'rightAligned',
			valueFormatter: (params: any) => currencyFormatter(params, false, true),
			flex: 1,
		},
	];

	useEffect(() => {
		loadWorkers();
	}, []);

	useEffect(() => {
		if (gridReady) loadGridData(curMonth, curYear);
	}, [gridReady, workerFilter]);

	const onGridReady = () => {
		setGridReady(true);
	};

	const loadGridData = (month: number, year: number) => {
		setData([]);
		setGridLoading(true);

		let payload: any = {
			job_id: props.jobId,
			month: month,
			year: year,
			...getTimezone(),
		};

		// Apply the worker filter if required
		if (workerFilter && workerFilter.length > 0) payload.worker_id = workerFilter;

		axios
			.post('/api/jobs/jobs_list/job_card/time_sheets/allocation_history', payload)
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);
				setData(res.data);
			})
			.catch(() => showToast('error'))
			.finally(() => setGridLoading(false));
	};

	const loadWorkers = () => {
		moduleCtx.getWorkers().then((res: any) => {
			const result = res.map((r: any) => {
				return {
					label: r.name,
					value: r.id,
				};
			});
			setWorkers(result);
		});
	};

	const handleWorkerFilter = (option: any) => {
		setGridLoading(true);

		if (!option) {
			setWorkerFilter(null);
			return;
		}

		if (option.value.length > 0) {
			setWorkerFilter(option.value);
		}
	};

	const handleClearWorkerFilter = () => {
		if (workerFilterRef.current) {
			workerFilterRef.current.clearValue();
		}
	};

	const handleExport = () => {
		if (gridReady && gridRef.current) {
			gridRef.current.exportDataAsExcel();
		}
	};

	return (
		<DataGrid
			onGridReady={onGridReady}
			ref={gridRef}
			title={'Allocation History'}
			cols={columns}
			data={data}
			useSearch={false}
			filterButtons={true}
			filterOptions={{
				displayCurrentMonth: loadGridData,
			}}
			extraFooter={
				<IonRow>
					<IonCol size='6' className='row-lower-controls-left ps-0'>
						<div className='row-lower-controls-container'>
							<SelectStyled
								className='worker-filter'
								placeholder='Select a name to filter by worker...'
								forwardRef={workerFilterRef}
								options={workers}
								onChange={(option: any) => handleWorkerFilter(option)}
							/>
							<IonButton
								color='secondary'
								disabled={!workerFilter || gridLoading}
								onClick={() => handleClearWorkerFilter()}
							>
								Reset Worker
							</IonButton>
						</div>
					</IonCol>
					<IonCol size='6' className='row-lower-controls-right pe-0'>
						<div className='row-lower-controls-container'>
							<IonButton color='tertiary' onClick={handleExport}>
								Export XLSX
							</IonButton>
						</div>
					</IonCol>
				</IonRow>
			}
		/>
	);
};

export default AllocationHistory;

import { useEffect, useRef, useState } from 'react';
import DataGrid from '../../../../../components/DataGrid/DataGrid';
import axios from '../../../../../lib/axios';
import { currencyFormatter } from '../../../../../helpers/currencyFormatter';
import { showToast } from '../../../../../lib/toast';
import {
	dataRow,
	formatCell,
	getCellClass,
	headerRow,
	subHeaderRow,
	subTotalRow,
	totalRow,
} from '../workertimesheets-functions';
import { ColDef } from '@ag-grid-community/core';

type Props = {
	jobId: string;
};

const CumulativeCost: React.FC<Props> = (props: Props) => {
	const gridRef: any = useRef<any>();
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [data, setData] = useState<Array<any>>([]);
	const columns: Array<ColDef> = [
		{ headerName: '', field: 'col_a', cellClass: getCellClass, minWidth: 260, flex: 1 },
		{ headerName: '', field: 'col_b', cellClass: getCellClass, minWidth: 260, flex: 1 },
		{
			headerName: '',
			field: 'col_c',
			cellClass: getCellClass,
			valueFormatter: (params: any) => formatCell(params, { hoursFormatter }),
			flex: 1,
		},
		{
			headerName: '',
			field: 'col_d',
			cellClass: getCellClass,
			valueFormatter: (params: any) => formatCell(params, { currencyFormatter }),
			flex: 1,
		},
		{ headerName: '', field: 'col_e', cellClass: getCellClass, minWidth: 260, flex: 1 },
		{
			headerName: '',
			field: 'col_f',
			cellClass: getCellClass,
			valueFormatter: (params: any) => formatCell(params, { hoursFormatter }),
			flex: 1,
		},
		{
			headerName: '',
			field: 'col_g',
			cellClass: getCellClass,
			valueFormatter: (params: any) => formatCell(params, { currencyFormatter }),
			flex: 1,
		},
		{
			headerName: '',
			field: 'col_h',
			cellClass: getCellClass,
			valueFormatter: (params: any) => formatCell(params, { currencyFormatter }),
			flex: 1,
		},
	];

	useEffect(() => {
		loadGridData();
	}, []);

	const loadGridData = () => {
		setData([]);
		setGridLoading(true);

		let payload: any = {
			job_id: props.jobId,
		};

		axios
			.post('/api/jobs/jobs_list/job_card/time_sheets/targets', payload)
			.then((res: any) => {
				let listing: Array<any> = [];
				let grandTotal: number = 0;
				let estimateGrandTotal: number = 0;
				let diffGrandTotal: number = 0;

				if (Object.keys(res.data).length > 0) {
					// Build the rows of headers, sections, and data
					for (let i = 0; i < Object.keys(res.data).length; i++) {
						const skillTitle: string = Object.keys(res.data)[i];
						let skillData: any = res.data[Object.keys(res.data)[i]];
						let skillTotal: number = 0;

						if (i === 0) listing.push(headerRow('main'));

						// Sub-title: skill name
						listing.push(subHeaderRow('alpha', skillTitle));

						// Data: timesheet data
						for (let j = 0; j < skillData.length; j++) {
							listing.push(dataRow('main', skillData[j], j + 1 === skillData.length));

							// Calculate running totals
							skillTotal += skillData[j].cost;
							grandTotal += skillTotal;
							estimateGrandTotal += skillData[j].estimate_cost;
						}

						// Sub-total: includes estimate data and calculated diff value
						let skillSubTotalData: any = skillData[skillData.length - 1];
						skillSubTotalData = {
							...skillSubTotalData,
							diff: skillSubTotalData.estimate_cost - skillSubTotalData.cost,
						};
						diffGrandTotal += skillSubTotalData.diff;
						listing.push(subTotalRow(skillTitle, skillTotal, skillSubTotalData));
					}

					// Grand total
					listing.push(totalRow(grandTotal, estimateGrandTotal, diffGrandTotal));

					// Give each row a unique ID to enable grid data changes without a full redraw
					listing = listing.map((item: any, rowId: number) => ({ ...item, rowId }));

					setData(listing);
				}

				setGridLoading(false);
			})
			.catch(() => showToast('error'))
			.finally(() => setGridLoading(false));
	};

	const hoursFormatter = (hours: number) => {
		return !isNaN(Number(hours)) ? Number(hours).toLocaleString() : null;
	};

	return (
		<div className='cumulative-cost-container'>
			<DataGrid
				className='grid-theme-alpha'
				ref={gridRef}
				title={'Cumulative Cost Allocation Vs. Cost Estimate'}
				cols={columns}
				data={data}
				useSearch={false}
				compact={true}
				rowIdName='rowId'
				exportExcelButton={true}
			/>
		</div>
	);
};

export default CumulativeCost;

import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useIonAlert, IonRow, IonCol, IonButton } from '@ionic/react';
import axios from '../../../../lib/axios';
import { useContext, useState, useRef } from 'react';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { authContext } from '../../../../contexts/AuthContext';
import { showToast } from '../../../../lib/toast';
import ListFilterAddInvoice from '../components/ListFilterAddInvoice';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { getTimezone, underscoredToUppercasedWords } from '../../../../lib/functions';
import { moduleContext } from '../../../../contexts/ModuleContext';
import { ColDef } from '@ag-grid-community/core';

const AddInvoiceList: React.FC = () => {
	const authCtx: any = useContext(authContext);
	const moduleCtx = useContext<any>(moduleContext);
	const [data, setData] = useState<Array<any>>([]);
	const [tableTitle, setTableTitle] = useState<string>('');
	const [curMonth, setCurMonth] = useState<number>(0);
	const [curYear, setCurYear] = useState<number>(0);
	const [curFilterPayload, setCurFilterPayload] = useState<any>(null);
	const [estimateId, setEstimateId] = useState<string | null>(null);
	const [jobId, setJobId] = useState<string | null>(null);
	const [jobData, setJobData] = useState<any>({});
	const [btnLblCompleteJob, setBtnLblCompleteJob] = useState<string>('Complete Job');
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [presentAlert] = useIonAlert();
	const permissionTo = usePermissionTo('jobs.invoices_list');
	let history = useHistory();

	const loadGridData = (month: number, year: number, payload: any = null) => {
		setEstimateId(null);
		setJobId(null);
		setData([]);

		if (payload === null) payload = curFilterPayload;

		axios
			.post('/api/jobs/estimates_list', {
				month: month,
				year: year,
				...getTimezone(),
				status: 'approved',
				...payload,
			})
			.then((res: any) => {
				setCurMonth(month);
				setCurYear(year);

				setData(res.data);
			});
	};

	const handleListFilterChange = (label: string, payload: any) => {
		setCurFilterPayload(payload);
		loadGridData(curMonth, curYear, payload);
		setTableTitle(`Add Invoice: Estimates - ${label}`);
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'delete' && !params.rowPinned) {
			params.node.setSelected(true);
			setEstimateId(params.data.id);
			setJobId(params.data.job_id);
			setJobData({ status_enum: params.data.job_status });
			setBtnLblCompleteJob(
				params.data.job_status !== 'completed' ? 'Complete Job' : 'Un-Complete Job'
			);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'delete' && !params.rowPinned) {
			handleCellClicked(params);
			handleAddInvoice();
		}
	};

	const handleAddInvoice = () => {
		if (permissionTo('create', 'jobs.invoices_list.add_invoice')) {
			const path = `/jobs/invoices_list/add_invoice/${estimateId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const handleCompleteJob = async () => {
		if (permissionTo('update', 'jobs.jobs_list.job_card')) {
			setIsLoading(true);

			const setCompleted: boolean = jobData.status_enum !== 'completed';
			await moduleCtx.setJobComplete(jobId, setCompleted);

			// Reload the grid
			loadGridData(curMonth, curYear);

			setIsLoading(false);
		} else {
			showToast('permission');
		}
	};

	const handleDelete = (id: string) => {
		if (permissionTo('delete')) {
			presentAlert({
				header: 'Delete Estimate',
				message: 'Are you sure you want to delete this estimate?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleDeleteGo(id);
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		setEstimateId(null);
		setJobId(null);

		axios
			.delete(`/api/jobs/estimates_list/estimates_card/${id}`)
			.then(() => {
				loadGridData(curMonth, curYear, curFilterPayload);
				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const columns: Array<ColDef> = [
		{
			field: 'number',
			headerName: 'Estimate Number',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'job_name',
			headerName: 'Job Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'client_name',
			headerName: 'Client Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'value',
			headerName: `Value Gross (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value) {
					return params.data.value.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'total_invoiced',
			headerName: `Invoiced (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.total_invoiced) {
					return params.data.total_invoiced.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'value_remaining',
			headerName: `Remaining (${authCtx.tenant.currency_symbol})`,
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value_remaining) {
					return params.data.value_remaining.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'issued_by',
			headerName: 'Issued By',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'job_status',
			headerName: 'Job Status',
			sortable: true,
			resizable: true,
			cellRenderer: (params: any) => {
				const statusWord = params.getValue();
				if (statusWord) {
					return underscoredToUppercasedWords(statusWord);
				}
			},
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') &&
					params.getValue() !== false && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const calcTotalCols = ['value', 'value_remaining', 'total_invoiced'];
	const totalRow = function (rowdata: any) {
		let result: any = [{}];

		// Calculate total columns
		calcTotalCols.forEach(function (params) {
			result[0][params] = 0;
			rowdata.forEach(function (line: any) {
				result[0][params] += line[params];
			});
		});

		// Clean-up row output
		result[0]['client_name'] = `Totals (${authCtx.tenant.currency_symbol}):`;
		result[0]['delete'] = false;

		return result;
	};

	return (
		<>
			<DataGrid
				title={tableTitle}
				cols={columns}
				data={data}
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				rowCursorPointer={permissionTo('update')}
				filterButtons={true}
				filterOptions={{
					displayCurrentMonth: loadGridData,
					defaultAllTime: true,
				}}
				totalsRow={totalRow}
				extraFooter={
					<IonRow>
						<IonCol size='8' className='row-lower-controls-left ps-0'>
							<ListFilterAddInvoice onChange={handleListFilterChange} />
						</IonCol>
						<IonCol size='4' className='row-lower-controls-right pe-0'>
							<div>
								<IonButton
									color='secondary'
									disabled={jobId === null}
									onClick={() => handleCompleteJob()}
								>
									{btnLblCompleteJob}
								</IonButton>
								<IonButton
									color='success'
									disabled={estimateId === null}
									onClick={() => handleAddInvoice()}
								>
									Add Invoice
								</IonButton>
							</div>
						</IonCol>
					</IonRow>
				}
			/>
		</>
	);
};

export default AddInvoiceList;

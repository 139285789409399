import { useEffect, useState } from 'react';
import { IonRow, IonCol, IonButton, IonButtons, IonCard } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import TitleBar from '../../../components/TitleBar/TitleBar';
import DataGrid from '../../../components/DataGrid/DataGrid';
import './../Workers.scss';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import RadioButtons from '../../../components/UI/RadioButtons/RadioButtons';
import { ColDef } from '@ag-grid-community/core';

const WorkersListIndex: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const defaultFilter = { label: 'All', payload: null };
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [tableTitle, setTableTitle] = useState<string>(defaultFilter.label);
	const [workerId, setWorkerId] = useState<string | null>(null);
	let history = useHistory();

	useEffect(() => {
		handleStatusFilterChange(defaultFilter.label, defaultFilter.payload);
	}, []);

	const loadGridData = (payload: any) => {
		setGridLoading(true);
		setWorkerId(null);
		setData([]);

		axios
			.post('/api/workers/workers_list', payload)
			.then((res: any) => {
				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleStatusFilterChange = (label: string, payload: any) => {
		loadGridData(payload);
		setTableTitle(`Workers - ${label}`);
	};

	const handleRowClicked = (params: any) => {
		params.node.setSelected(true);
		setWorkerId(params.data.id);
	};

	const handleRowDoubleClicked = (params: any) => {
		handleRowClicked(params);
		handleEditWorker();
	};

	const handleEditWorker = () => {
		if (permissionTo('update', 'workers.workers_list.worker_card')) {
			const path = `/workers/workers_list/worker_card/${workerId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const triggerAddWorker = () => {
		if (permissionTo('create', 'workers.workers_list.add_new')) {
			const path = '/workers/workers_list/add_new';
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const triggerImportWorkers = () => {
		if (permissionTo('create', 'workers.workers_list.import')) {
			const path = '/workers/workers_list/import';
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const columns: Array<ColDef> = [
		{
			headerName: 'Name',
			field: 'last_name',
			sortable: true,
			resizable: true,
			sort: 'asc',
			cellRenderer: (params: any) => {
				return `${params.data.first_name} ${params.data.last_name}`;
			},
			flex: 1,
		},
		{
			field: 'job_title',
			headerName: 'Job Title',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'status',
			headerName: 'Status',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'mobile',
			headerName: 'Mobile Number',
			resizable: true,
			flex: 1,
		},
		{
			field: 'email',
			headerName: 'Email Address',
			sortable: true,
			resizable: true,
			minWidth: 300,
			flex: 1,
		},
		{
			field: 'security_type',
			headerName: 'Security Type',
			sortable: true,
			resizable: true,
			suppressSizeToFit: true,
			flex: 1,
		},
	];

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card filter-data-table full-height-card'>
					<DataGrid
						title={tableTitle}
						cols={columns}
						data={data}
						rowClickedFunction={handleRowClicked}
						rowDoubleClickedFunction={handleRowDoubleClicked}
						rowCursorPointer={permissionTo('update')}
						extraFooter={
							<IonRow className='data-table-buttons'>
								<IonCol size='8' className='ps-0'>
									<StatusFilter defaultSelected='all' onChange={handleStatusFilterChange} />
								</IonCol>
								<IonCol size='4' className='text-right pe-0'>
									<IonButton
										color='primary'
										disabled={gridLoading}
										onClick={() => triggerImportWorkers()}
									>
										Import XLSX
									</IonButton>
									<IonButton
										color='primary'
										disabled={workerId === null}
										onClick={() => handleEditWorker()}
									>
										Edit Worker
									</IonButton>
									<IonButton
										color='success'
										disabled={gridLoading}
										onClick={() => triggerAddWorker()}
									>
										Add New
									</IonButton>
								</IonCol>
							</IonRow>
						}
					/>
				</IonCard>
			</div>
		</>
	);
};

export default WorkersListIndex;

interface SFProps {
	defaultSelected: string;
	onChange?: Function;
}

const StatusFilter: React.FC<SFProps> = ({ defaultSelected, onChange }) => {
	const [btnSel, setBtnSel] = useState<Array<any>>([defaultSelected]);
	const statusFilterItems: Array<any> = [
		{
			label: 'All',
			value: 'all',
		},
		{
			label: 'Directly Employed',
			value: 'directly',
		},
		{
			label: 'Self-Employed',
			value: 'self',
		},
		{
			label: 'Archived',
			value: 'archived',
		},
		{
			label: 'Part Completed',
			value: 'part-completed',
		},
	];

	const updateSelection = (e: Array<any>) => {
		setBtnSel(e);

		if (onChange) {
			const id = e[0];

			let item = statusFilterItems.find((item: any) => {
				return item.value = e[0];
			});

			switch (id) {
				case 'all':
					onChange(item.label, null);
					break;
				case 'directly':
				case 'self':
					onChange(item.label, { type_of_engagement: id });
					break;
				case 'archived':
					onChange(item.label, { archived: true });
					break;
				case 'part-completed':
					onChange(item.label, { part_completed: true });
					break;
			}
		}
	};

	return (
		<RadioButtons options={statusFilterItems} onChange={updateSelection} value={btnSel} />
	);
};

import { useEffect, useState } from 'react';
import { IonRow, IonCol, IonButton, IonCard } from '@ionic/react';
import { useHistory } from 'react-router-dom';
import TitleBar from '../../../components/TitleBar/TitleBar';
import DataGrid from '../../../components/DataGrid/DataGrid';
import './Clients.scss';
import axios from '../../../lib/axios';
import { showToast } from '../../../lib/toast';
import { RouteIndexComponent } from '../../../interfaces/Pages/RouteIndexComponent';
import { kpiFormatter } from '../../../helpers/kpiFormatter';
import { ColDef } from '@ag-grid-community/core';

const ClientList: React.FC<RouteIndexComponent> = ({ uid, routeTitle, permissionTo }) => {
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [clientId, setClientId] = useState<string | null>(null);
	let history = useHistory();

	useEffect(() => {
		loadGridData();
	}, []);

	const loadGridData = () => {
		setGridLoading(true);
		setClientId(null);
		setData([]);

		axios
			.post('/api/jobs/clients_list')
			.then((res: any) => {
				setData(res.data);
			})
			.finally(() => {
				setGridLoading(false);
			});
	};

	const handleRowClicked = (params: any) => {
		params.node.setSelected(true);
		setClientId(params.data.id);
	};

	const handleRowDoubleClicked = (params: any) => {
		handleRowClicked(params);
		handleEditClient();
	};

	const handleEditClient = () => {
		if (permissionTo('update', 'jobs.clients_list.client_card')) {
			const path = `/jobs/clients_list/client_card/${clientId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const triggerAddClient = () => {
		if (permissionTo('create', 'jobs.clients_list.add_new')) {
			const path = '/jobs/clients_list/add_new';
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const triggerImportClients = () => {
		if (permissionTo('create', 'jobs.clients_list.import')) {
			const path = '/jobs/clients_list/import';
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	const columns: Array<ColDef> = [
		{
			field: 'company_name',
			headerName: 'Company Name',
			sortable: true,
			resizable: true,
			cellRenderer: (params: any) => {
				return params.getValue() ?? '-';
			},
			flex: 1,
		},
		{
			headerName: 'Contact Name',
			field: 'last_name',
			sortable: true,
			resizable: true,
			sort: 'asc',
			cellRenderer: (params: any) => {
				return `${params.data.first_name} ${params.data.last_name}`;
			},
			flex: 1,
		},
		{
			field: 'address',
			headerName: 'Contact Address',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'postcode',
			headerName: 'Contact Postcode',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'mobile',
			headerName: 'Contact Mobile',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'email',
			headerName: 'Contact Email',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'kpi',
			headerName: 'KPI',
			sortable: true,
			resizable: true,
			suppressSizeToFit: true,
			valueFormatter: kpiFormatter,
			type: 'rightAligned',
			flex: 1,
		},
	];

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card filter-data-table full-height-card'>
					<DataGrid
						title={'Clients'}
						cols={columns}
						data={data}
						rowClickedFunction={handleRowClicked}
						rowDoubleClickedFunction={handleRowDoubleClicked}
						rowCursorPointer={permissionTo('update')}
						exportExcelButton={true}
						appendedButtons={[
							<IonButton
								key={0}
								color='primary'
								disabled={gridLoading}
								onClick={() => triggerImportClients()}
							>
								Import XLSX
							</IonButton>,
							<IonButton
								key={1}
								color='primary'
								disabled={clientId === null}
								onClick={() => handleEditClient()}
							>
								Edit Client
							</IonButton>,
							<IonButton
								key={2}
								color='success'
								disabled={gridLoading}
								onClick={() => triggerAddClient()}
							>
								Add New
							</IonButton>,
						]}
					/>
				</IonCard>
			</div>
		</>
	);
};

export default ClientList;

import { useState, useEffect } from 'react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { IonCard, IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { TextField } from '../../../../components/Forms/FormFields';
import Form from '../../../../components/Forms/Form';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import { ColDef } from '@ag-grid-community/core';

interface RouteIndexComponentExtended extends RouteIndexComponent {
	usageMode?: string;
	usageModeCompletion?: Function;
}

const JobTypesIndex: React.FC<RouteIndexComponentExtended> = ({
	uid,
	routeTitle,
	permissionTo,
	usageMode,
	usageModeCompletion,
}) => {
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [presentAlert] = useIonAlert();

	useEffect(() => {
		loadGridData();
	}, []);

	const columns: Array<ColDef> = [
		{
			headerName: 'Job Types',
			field: 'type',
			sortable: true,
			sort: 'asc',
			flex: 1,
			cellRenderer: (params: any) => {
				return (
					<Form
						array={[
							{
								id: `item-${params.data._id}`,
								title: 'Job Types',
								type: 'text',
								value: params.data.type,
								db: ['type'],
							},
						]}
						forceEdit={permissionTo('update') && !usageMode}
						noButton={true}
						tableMode={true}
						permissionTo={permissionTo}
						endpoint={`/api/utilities/jobs/job_types`}
						endpointID={params.data._id}
					/>
				);
			},
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 60,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data._id)}
						/>
					)
				);
			},
		},
	];

	// Deletion not allowed when used as a modal
	if (usageMode === 'modal') {
		columns.pop();
	}

	const loadGridData = () => {
		setGridLoading(true);
		axios.get('/api/utilities/jobs/job_types').then((res) => {
			setData(res.data);
			setGridLoading(false);
		});
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		axios
			.delete(`/api/utilities/jobs/job_types/${id}`)
			.then((res) => {
				// Capture any grid changes and update state with those and the deletion
				let rowData: Array<any> = data;

				// Update grid row items to prevent value flipping
				rowData = updateRow(rowData);

				// Remove item
				rowData = rowData.filter((d) => d._id !== id);

				setData(rowData);

				showToast('deleted', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const handleCreate = () => {
		const parent = document.getElementById('add-job-type') as HTMLElement;
		const value = parent.getElementsByTagName('input')[0].value.toString();
		if (!value || parent.classList.contains('is-invalid')) {
			showToast('error', 'Please enter a new job type');
			return;
		}

		const toastID = toast.loading('Please wait...');

		axios
			.put('/api/utilities/jobs/job_types', { type: value })
			.then((res) => {
				// Capture any grid changes and update state with those and the new item
				let rowData: Array<any> = data;

				// Update grid row items to prevent value flipping
				rowData = updateRow(rowData);

				// Add item
				setData([...rowData, { _id: res.data._id, type: value }]);

				showToast('saved', null, toastID);

				if (usageModeCompletion) usageModeCompletion();
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const updateRow = (rowData: Array<any>) => {
		if (permissionTo('update')) {
			rowData.map((a: any) => {
				const gridElement = document.getElementById(`item-${a._id}`) as HTMLElement;
				if (gridElement) {
					const value = gridElement.getElementsByTagName('input')[0].value;
					const gridElementValue = value.length > 0 ? value.toString() : '';
					a.type = gridElementValue;
				}
				return a;
			});
		}

		return rowData;
	};

	// Build the output
	let output = (
		<>
			<DataGrid
				title={routeTitle}
				cols={columns}
				data={data}
				autoSize={true}
				showFooter={false}
				extraFooter={
					permissionTo('create') && (
						<IonRow>
							<IonCol size={'6'} className='ps-0'>
								<TextField
									id='add-job-type'
									title={'Add a new Job Type'}
									value={''}
									editMode={true}
									handleUpdateValue={handleCreate}
									noForm={true}
								/>
							</IonCol>
							<IonCol size={'6'} className='text-right pe-0'>
								<IonButton color='success' onClick={() => handleCreate()} disabled={gridLoading}>
									Create
								</IonButton>
							</IonCol>
						</IonRow>
					)
				}
			/>
		</>
	);

	switch (usageMode) {
		case 'modal':
			output = (
				<div className='usage-modal' style={{ height: '100%' }}>
					<div className='filter-data-table' style={{ height: '100%' }}>
						{output}
					</div>
				</div>
			);
			break;
		default:
			output = (
				<IonCard className='table-card filter-data-table' style={{ height: 'calc(100% - 69px)' }}>
					{output}
				</IonCard>
			);
			break;
	}

	return (
		<>
			{!usageMode && <TitleBar title={routeTitle} />}
			{output}
		</>
	);
};

export default JobTypesIndex;

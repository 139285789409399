import React, {
	useState,
	useEffect,
	useRef,
	useMemo,
	useCallback,
	ReactNode,
	forwardRef,
	useImperativeHandle,
} from 'react';
import { IonRow, IonCol, IonButton, IonSearchbar } from '@ionic/react';
import { DateTime } from 'luxon';
import { AgGridReact } from '@ag-grid-community/react';
import { ColDef, ColGroupDef, IServerSideDatasource, IServerSideGetRowsParams } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';

import axios from '../../lib/axios';
import { AxiosRequestConfig } from 'axios';
import FilterButtons from '../Forms/FilterButtons';

import '@ag-grid-community/styles/ag-grid.css'; // Core grid CSS, always needed
import '@ag-grid-community/styles/ag-theme-alpine.css'; // Optional theme CSS
import '../../assets/scss/ag-theme-alpine-compact.scss'; // Optional compact theme
import './DataGrid.scss';
import Loading from '../UI/Loading';

/* AG Grid license */
import { LicenseManager } from '@ag-grid-enterprise/core';
LicenseManager.setLicenseKey(
	'Using_this_AG_Grid_Enterprise_key_( AG-049716 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Propeller Studios Ltd. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( HumanFacta )_only_for_( 2 )_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_( HumanFacta )_need_to_be_licensed___( HumanFacta )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 25 October 2024 )____[v2]_MTcyOTgxMDgwMDAwMA==5aa7fcf62cdc548c1888287ff0bc7a10'
);

interface DataSource {
	url: string;
	method: 'POST' | 'PUT' | 'GET';
	data?: any;
}

interface Props {
	appendedButtons?: Array<any>;
	autoSize?: boolean;
	cellClickedFunction?: Function;
	cellDoubleClickedFunction?: Function;
	className?: string;
	cols: (ColDef | ColGroupDef)[];
	compact?: boolean;
	data?: Array<any>;
	dataSource?: DataSource;
	excelStyles?: any;
	exportButton?: boolean;
	exportExcelButton?: boolean;
	extraFooter?: ReactNode;
	filterButtons?: boolean;
	filterOptions?: any;
	rowIdName?: string;
	gridSizeChangedFunction?: Function;
	groupDefaultExpanded?: number;
	groupDisplayType?: string;
	groupRowRendererParams?: any;
	onGridReady?: Function;
	onFilterChanged?: Function;
	rowBuffer?: Number;
	rowClassRules?: any;
	rowClickedFunction?: Function;
	rowCursorPointer?: boolean;
	rowDoubleClickedFunction?: Function;
	rowSelection?: 'single' | 'multiple' | undefined;
	showFooter?: boolean;
	suppressRowHoverHighlight?: boolean;
	title: string | ReactNode;
	toolTip?: React.FC | null;
	totalsRow?: Function;
	useSearch?: boolean;
	paging?: boolean;
	pageSize?: number;
}

const DataGrid = forwardRef<any, Props>((props: Props, ref) => {
	const [search, setSearch] = useState<string>('');
	const [rowData, setRowData] = useState<Array<any>>([]);
	const [autoSize, setAutoSize] = useState<boolean>(props.autoSize ?? true);
	const [columnDefs, setColumnDefs] = useState<(ColDef | ColGroupDef)[]>([]);
	const [gridIsReady, setGridIsReady] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [paging, setPaging] = useState<boolean>(props.paging ?? false);
	const divRef = useRef<HTMLDivElement>(null);
	let rowStyle: any = {};
	let gridRef: any = useRef<any>();
	let gridClassName = 'ag-theme-alpine';
	let dynamicGridOptions: any = {};
	const PAGING_ITEMS = 100;

	useImperativeHandle(ref, () => ({
		search(value: string) {
			setSearch(value);
		},
		clearSearch() {
			setSearch('');
		},
		sizeToFit() {
			sizeToFit();
		},
		updateRow(item: any) {
			updateRow(item);
		},
		deselectAll() {
			gridRef.current.api.deselectAll();
		},
		selectAll() {
			gridRef.current.api.selectAll();
		},
		getColumnDefs() {
			return gridRef.current.api.getColumnDefs();
		},
		exportDataAsCsv() {
			triggerCSVExport();
		},
		exportDataAsExcel() {
			triggerExcelExport();
		},
		refreshDatasource() {
			gridRef.current.api.setServerSideDatasource(serverSideDatasource());
		},
		setColumnsVisible(columns: Array<string>, visible: boolean) {
			return gridRef.current.columnApi.setColumnsVisible(columns, visible);
		},
		showLoading() {
			setLoading(true);
		},
		hideLoading() {
			setLoading(false);
		},
		getData() {
			return rowData;
		}
	}));

	if (props.rowIdName && props.rowIdName.length > 0) {
		dynamicGridOptions.getRowId = (params: any) => params.data[props.rowIdName!];
	}

	if (props.hasOwnProperty('groupDefaultExpanded')) {
		dynamicGridOptions.groupDefaultExpanded = props.groupDefaultExpanded;
	}

	if (props.groupDisplayType && props.groupDisplayType.length > 0) {
		dynamicGridOptions.groupDisplayType = props.groupDisplayType;
	}

	if (props.hasOwnProperty('groupRowRendererParams') && props.groupRowRendererParams) {
		dynamicGridOptions.groupRowRendererParams = props.groupRowRendererParams;
	}

	if (props.rowCursorPointer === true) {
		rowStyle.cursor = 'pointer';
	}

	// Compact mode allows us to use a custom scss file that extends the main theme class having the
	// same name with '-compact' appended to the end
	if (props.compact === true) {
		gridClassName += '-compact';
	}

	if (props.className && props.className.length > 0) {
		gridClassName += ' ' + props.className;
	}

	useEffect(() => {
		window.addEventListener('resize', sizeToFit);
		return () => {
			window.removeEventListener('resize', sizeToFit);
		};
	}, []);

	useEffect(() => {
		setColumnDefs(props.cols);
	}, [props.cols]);

	useEffect(() => {
		setPaging(props.paging ?? false);
	}, [props.paging]);

	useEffect(() => {
		if (props.autoSize !== undefined) {
			setAutoSize(props.autoSize);
		}
	}, [props.autoSize]);

	useEffect(() => {
		if (
			gridRef !== undefined &&
			gridRef.current !== undefined &&
			gridRef.current.api !== undefined
		) {
			gridRef.current.api.setQuickFilter(search);
		}
	}, [search]);

	useEffect(() => {
		// for infinite scroll / lazy loading when using server-side data:
		// https://ag-grid.com/react-data-grid/server-side-model-infinite-scroll/
		// delete the line below when using lazy loading of data:
		if (props.data) {
			setRowData(props.data);
			setTotalRow(props.data);
		}
	}, [props.data]);

	useEffect(() => {
		if (gridRef && gridRef.current && gridRef.current.api) {
			//gridRef.current.api.refreshServerSide();
			gridRef.current.api.setServerSideDatasource(serverSideDatasource());
		}
	}, [props.dataSource]);

	const setTotalRow = (data: Array<any>) => {
		if (
			gridRef &&
			gridRef.current &&
			gridRef.current.api !== undefined &&
			props.totalsRow !== undefined
		) {
			gridRef.current.api.setPinnedBottomRowData(props.totalsRow(data));
		}
	};

	let selecYearOptions: Array<{ label: string; value: number }> = [];
	const startYear = 2022;
	const endYear = parseInt(DateTime.now().toFormat('yyyy'), 10);
	for (let iYear = startYear; iYear <= endYear; iYear++) {
		selecYearOptions.push({
			label: String(iYear),
			value: iYear,
		});
	}

	const sizeToFit = useCallback(() => {
		if (gridRef && gridRef.current && autoSize === true) {
			gridRef.current.api.sizeColumnsToFit();
		}
	}, []);

	const handleSearchChange = (ev: any) => {
		let query: string = '';
		const target = ev.target as HTMLIonSearchbarElement;
		if (target) query = target.value!.toLowerCase();
		setSearch(query);
	};

	const triggerCSVExport = useCallback(() => {
		let fileName = 'Export';
		if (props.title && typeof props.title === 'string') {
			fileName = props.title;
		}
		let fileHeader = fileName.replaceAll('.', '') + ' - ' + DateTime.now().toFormat('dd_MM_yyyy');
		const params = {
			fileName: fileHeader,
		};
		if (gridRef && gridRef.current) {
			gridRef.current.api.exportDataAsCsv(params);
		}
	}, []);

	const triggerExcelExport = useCallback(() => {
		let fileName = 'Export';
		if (props.title && typeof props.title === 'string') {
			fileName = props.title;
		}
		let fileHeader = fileName.replaceAll('.', '') + ' - ' + DateTime.now().toFormat('dd_MM_yyyy');
		const params = {
			fileName: fileHeader,
		};
		if (gridRef && gridRef.current) {
			gridRef.current.api.exportDataAsExcel(params);
		}
	}, []);

	const defaultColDef = useMemo<any>(() => {
		const options: any = { sortable: true };
		if (props.toolTip) options.tooltipComponent = props.toolTip;
		return options;
	}, []);

	const doGet = (params: any): Promise<Array<any>> => {
		return new Promise((res, rej) => {
			if (
				props.dataSource &&
				props.dataSource.url &&
				gridRef !== undefined &&
				gridRef.current !== undefined &&
				gridRef.current.api !== undefined
			) {
				setLoading(true);
				let data: any = { ...props.dataSource.data };

				if (paging === true) {
					data.skip = params.startRow;
					data.take = params.endRow - params.startRow;
				}

				let config: AxiosRequestConfig = {
					url: props.dataSource.url,
					method: props.dataSource.method,
					data,
				};

				if (params.sortModel.length > 0) {
					data.sort_model = params.sortModel;
				}

				axios
					.request(config)
					.then((result) => {
						setLoading(false);
						if (result.data) {
							res(result.data);
						} else {
							rej('no data');
						}
					})
					.catch((e) => {
						rej(e);
						setLoading(false);
					});
			}
		});
	};

	const updateRow = (item: any) => {
		gridRef.current.api.forEachNode((node: any) => {
			if (node.data._id === item._id) {
				node.updateData(item);
			}
		});
	};

	const onGridReady = useCallback((params: any) => {
		sizeToFit();
		setGridIsReady(true);
		if (props.dataSource) {
			params.api.setServerSideDatasource(serverSideDatasource());
		}
		if (props.onGridReady) {
			props.onGridReady();
		}
	}, []);

	const onFilterChanged = useCallback((e: any) => {
		if (props.onFilterChanged) {
			props.onFilterChanged(e);
		}
	}, []);

	const renderMainFooter = () => {
		return (
			<div className='data-grid-footer'>
				<IonRow>
					<IonCol size={props?.filterOptions?.rowSlot ? '12' : '9'} className='p-0 m-0'>
						{props.filterButtons === true && (
							<FilterButtons
								gridIsReady={gridIsReady}
								monthStart={props?.filterOptions?.monthStart}
								selectOptions={props?.filterOptions?.selectOptions}
								displayCurrentMonth={props?.filterOptions?.displayCurrentMonth}
								initialLoad={props?.filterOptions?.initialLoad}
								displayAllTime={props?.filterOptions?.displayAllTime}
								displayAnnual={props?.filterOptions?.displayAnnual}
								displaySelect={props?.filterOptions?.displaySelect}
								rowSlot={props?.filterOptions?.rowSlot}
								defaultAllTime={props?.filterOptions?.defaultAllTime}
								forceDate={props?.filterOptions?.forceDate}
							/>
						)}
					</IonCol>
					{!props?.filterOptions?.rowSlot && (
						<IonCol size={'3'} className='p-0 m-0 text-right'>
							<div className={props.filterButtons ? 'filter-shift' : ''}>
								{props.exportButton === true && (
									<IonButton color='tertiary' onClick={triggerCSVExport}>
										Export XLSX
									</IonButton>
								)}
								{props.exportExcelButton === true && (
									<IonButton color='tertiary' onClick={triggerExcelExport}>
										Export XLSX
									</IonButton>
								)}
								{props.appendedButtons && props.appendedButtons}
							</div>
						</IonCol>
					)}
				</IonRow>
			</div>
		);
	};

	const serverSideDatasource = useCallback((): IServerSideDatasource => {
		return {
			getRows: (params: IServerSideGetRowsParams) => {
				gridRef.current.api.hideOverlay();
				doGet(params.request)
					.then((response: any) => {
						if (response['total'] !== undefined && response['total'] !== null) {
							// Infinite scroll
							setTotalRow(response['rows']);
							if (response['rows'].length > 0) {
								params.success({ rowData: response['rows'], rowCount: response['total'] });
							} else {
								params.success({ rowData: [], rowCount: 0 });
								gridRef.current.api.showNoRowsOverlay();
							}
						} else if (response['totals_rows'] !== undefined && response['totals_rows'] !== null) {
							// Data with pre-calculated totals rows
							setTotalRow(response['totals_rows']);
							if (response['rows'].length > 0) {
								params.success({ rowData: response['rows'], rowCount: response['rows'].length });
							} else {
								params.success({ rowData: [], rowCount: 0 });
								gridRef.current.api.showNoRowsOverlay();
							}
						} else {
							// Standard rows of data
							setTotalRow(response);
							if (response.length > 0) {
								params.success({ rowData: response, rowCount: response.length });
							} else {
								params.success({ rowData: [], rowCount: 0 });
								gridRef.current.api.showNoRowsOverlay();
							}
						}
					})
					.catch((e) => {
						params.fail();
					});
			},
		};
	}, [props]);

	return (
		<div className='data-grid-container' ref={divRef}>
			<div className='data-grid-header'>
				<IonRow>
					<IonCol size={'8'} className='p-0'>
						{props.title && typeof props.title === 'string' ? (
							<h4 className='font-bold data-grid-title'>{props.title}</h4>
						) : (
							props.title
						)}
					</IonCol>
					<IonCol size={'4'} className='p-0'>
						{props.useSearch !== false && (
							<>
								<IonSearchbar
									onInput={(e) => handleSearchChange(e)}
									onIonClear={(ev) => setSearch('')}
									onIonBlur={(ev) => handleSearchChange(ev)}
									value={search}
								/>
							</>
						)}
					</IonCol>
				</IonRow>
			</div>
			<div className='data-grid'>
				{loading && <Loading overlay={true} />}
				<AgGridReact
					rowBuffer={props.rowBuffer ?? 0}
					maxBlocksInCache={10}
					modules={[
						ServerSideRowModelModule,
						ClientSideRowModelModule,
						CsvExportModule,
						ExcelExportModule,
						RowGroupingModule,
					]}
					className={gridClassName}
					rowModelType={
						props.paging === true && props.dataSource === undefined
							? 'infinite'
							: props.dataSource
							? 'serverSide'
							: 'clientSide'
					}
					infiniteInitialRowCount={props.pageSize ?? PAGING_ITEMS}
					ref={gridRef}
					rowData={rowData}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					rowStyle={rowStyle}
					onGridReady={onGridReady}
					animateRows={true}
					suppressCellFocus={true}
					tooltipShowDelay={600}
					overlayLoadingTemplate={'<div></div>'}
					overlayNoRowsTemplate={
						'<span className="ag-overlay-loading-center">No data found to display.</span>'
					}
					rowSelection={props.rowSelection}
					onCellClicked={(e: any) => {
						if (props.cellClickedFunction) {
							props.cellClickedFunction(e);
						}
					}}
					onCellDoubleClicked={(e: any) => {
						if (props.cellDoubleClickedFunction) {
							props.cellDoubleClickedFunction(e);
						}
					}}
					onRowClicked={(e: any) => {
						if (props.rowClickedFunction) {
							props.rowClickedFunction(e);
						}
					}}
					onRowDoubleClicked={(e: any) => {
						if (props.rowDoubleClickedFunction) {
							props.rowDoubleClickedFunction(e);
						}
					}}
					onGridSizeChanged={(e: any) => {
						if (props.gridSizeChangedFunction) {
							props.gridSizeChangedFunction(e);
						}
					}}
					onFilterChanged={onFilterChanged}
					suppressRowHoverHighlight={props.suppressRowHoverHighlight}
					rowClassRules={props.rowClassRules ?? null}
					excelStyles={props.excelStyles ?? null}
					{...dynamicGridOptions}
				/>
			</div>
			{props.showFooter !== false && renderMainFooter()}
			{props.extraFooter && props.extraFooter}
		</div>
	);
});

export default DataGrid;

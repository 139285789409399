import { useState, useEffect, useRef } from 'react';
import TitleBar from '../../../../components/TitleBar/TitleBar';
import { IonCard, IonRow, IonCol, IonButton, useIonAlert } from '@ionic/react';
import { NumberField, SingleDatePicker } from '../../../../components/Forms/FormFields';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { toast } from 'react-toastify';
import { showToast } from '../../../../lib/toast';
import './NationalInsuranceRates.scss';
import { RouteIndexComponent } from '../../../../interfaces/Pages/RouteIndexComponent';
import { DateTime } from 'luxon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { ColDef } from '@ag-grid-community/core';

const NationalInsuranceRatesIndex: React.FC<RouteIndexComponent> = ({
	uid,
	routeTitle,
	permissionTo,
}) => {
	const dateFromRef = useRef<any>();
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [presentAlert] = useIonAlert();
	const highlightRowRules = {
		'highlight-row': (params: any) =>
			params.rowIndex === 0 && params.api.getDisplayedRowCount() > 1,
	};

	useEffect(() => {
		loadGridData();
	}, []);

	const columns: Array<ColDef> = [
		{
			headerName: "Employer's National Insurance Contribution %",
			field: 'rate',
			cellClassRules: {
				...highlightRowRules,
			},
			flex: 1,
			sortable: true,
			cellRenderer: (params: any) => {
				return params.value + '%';
			},
		},
		{
			headerName: 'Date From',
			field: 'date_from',
			cellClassRules: {
				...highlightRowRules,
			},
			flex: 1,
			sortable: true,
			cellRenderer: (params: any) => {
				let output = '-';
				try {
					if (params.value) {
						output = DateTime.fromISO(params.value).toFormat('dd/MM/yyyy');
					}
				} catch (err) {
					output = '-';
				}
				return output;
			},
		},
		{
			headerName: 'Date To',
			field: 'date_to',
			cellClassRules: {
				...highlightRowRules,
			},
			flex: 1,
			sortable: true,
			cellRenderer: (params: any) => {
				let output = '-';
				try {
					if (params.value) {
						output = DateTime.fromISO(params.value).toFormat('dd/MM/yyyy');
					}
				} catch (err) {
					output = '-';
				}
				return output;
			},
		},
		{
			// Only show delete button on last row, never first row
			headerName: '',
			field: 'delete',
			cellClassRules: {
				'icon-grid': (params: any) =>
					params.api.getDisplayedRowCount() > 1 && params.rowIndex === 0,
				...highlightRowRules,
			},
			width: 60,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				if (params.api.getDisplayedRowCount() > 1 && params.rowIndex === 0) {
					return (
						permissionTo('delete') && (
							<FontAwesomeIcon
								icon={faCircleXmark}
								className='icon-danger icon-grid'
								onClick={() => handleDelete(params.data._id)}
							/>
						)
					);
				} else {
					return null;
				}
			},
		},
	];

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		axios.get('/api/utilities/general/national_insurance_rates').then((res) => {
			setData(res.data);
			setGridLoading(false);
		});
	};

	const handleDelete = (id: string) => {
		presentAlert({
			header: 'Delete Item',
			message: 'Are you sure you want to delete this item?',
			buttons: [
				{
					text: 'Cancel',
					role: 'cancel',
				},
				{
					text: 'OK',
					role: 'confirm',
					handler: () => {
						handleDeleteGo(id);
					},
				},
			],
		});
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		axios
			.delete(`/api/utilities/general/national_insurance_rates/${id}`)
			.then((res) => {
				loadGridData();

				showToast('deleted', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	const handleCreate = () => {
		const dateFrom = dateFromRef.current.value;
		const rateParent = document.getElementById('add-rate') as HTMLElement;
		const rate = rateParent.getElementsByTagName('input')[0].value;

		if (String(dateFrom).length === 0 || String(rate).length === 0) {
			showToast('error', 'Please fill-in all fields');
			return;
		}

		// Check dateFrom is after the previous dateFrom (and previous dateTo if set)
		if (data.length > 0) {
			const dateFromCur = DateTime.fromFormat(dateFrom, 'yyyy-MM-dd HH:mm:ss');
			let dateFromPrev = data[0].date_from;
			let dateToPrev = data[0].date_to;

			if (dateFromPrev && dateFromPrev.length > 0) {
				dateFromPrev = DateTime.fromISO(dateFromPrev);

				if (dateFromCur <= dateFromPrev) {
					showToast('error', "Please ensure the new date is after the previous 'Date From'");
					return;
				}
			}

			if (dateToPrev && dateToPrev.length > 0) {
				dateToPrev = DateTime.fromISO(dateToPrev);

				if (dateFromCur <= dateToPrev) {
					showToast('error', "Please ensure the new date is after the previous 'Date To'");
					return;
				}
			}
		}

		const toastID = toast.loading('Please wait...');

		const payload = {
			rate: Number(rate),
			date_from: dateFrom,
		};

		axios
			.put('/api/utilities/general/national_insurance_rates', payload)
			.then((res) => {
				loadGridData();

				showToast('saved', null, toastID);
			})
			.catch((err) => {
				showToast('error', null, toastID);
			});
	};

	return (
		<>
			<div className={`component-${uid.replaceAll('.', '-')}`}>
				<TitleBar title={routeTitle} />
				<IonCard className='table-card filter-data-table full-height-card'>
					<DataGrid
						title={routeTitle}
						cols={columns}
						data={data}
						autoSize={true}
						extraFooter={
							permissionTo('create') && (
								<IonRow>
									<IonCol size={'10'} className='ps-0' style={{display: 'flex'}}>
										<label htmlFor='add-rate'>Employer's National Insurance Contribution %</label>
										<NumberField
											id='add-rate'
											value={''}
											useLabels={false}
											editMode={true}
											handleUpdateValue={handleCreate}
											hideSpinner={true}
											noForm={true}
										/>
										<label htmlFor='add-date_from'>Date From</label>
										<SingleDatePicker
											id='add-date_from'
											innerRef={dateFromRef}
											value={''}
											useLabels={false}
											editMode={true}
											handleUpdateValue={handleCreate}
											noForm={true}
										/>
									</IonCol>
									<IonCol size={'2'} className='text-right pe-0'>
										<IonButton
											color='success'
											onClick={() => handleCreate()}
											disabled={gridLoading}
										>
											Add
										</IonButton>
									</IonCol>
								</IonRow>
							)
						}
					/>
				</IonCard>
			</div>
		</>
	);
};

export default NationalInsuranceRatesIndex;

import { useContext, useEffect, useRef, useState } from 'react';
import { IonRow, IonCol, useIonAlert, IonButton } from '@ionic/react';
import DataGrid from '../../../../components/DataGrid/DataGrid';
import axios from '../../../../lib/axios';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory, useParams } from 'react-router-dom';
import { showToast } from '../../../../lib/toast';
import { toast } from 'react-toastify';
import { dateFormatter } from '../../../../helpers/dateFormatter';
import usePermissionTo from '../../../../utils/custom-hooks/PermissionTo';
import { authContext } from '../../../../contexts/AuthContext';
import { getTimezone, underscoredToUppercasedWords } from '../../../../lib/functions';
import { ColDef } from '@ag-grid-community/core';

const EstimatesIndex: React.FC = () => {
	const authCtx: any = useContext(authContext);
	const [data, setData] = useState<Array<any>>([]);
	const [gridLoading, setGridLoading] = useState<boolean>(true);
	const [estimateId, setEstimateId] = useState<string | null>(null);
	const { jobId }: any = useParams();
	const [presentAlert] = useIonAlert();
	const permissionTo = usePermissionTo('jobs.estimates_list');
	let history = useHistory();

	useEffect(() => {
		loadGridData();
	}, []);

	const colData: Array<ColDef> = [
		{
			field: 'number',
			headerName: 'Estimate Number',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'job_name',
			headerName: 'Job Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'issue_date',
			headerName: 'Issue Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
			sort: 'desc',
		},
		{
			field: 'expiry_date',
			headerName: 'Expiry Date',
			valueFormatter: dateFormatter,
			filter: 'agDateColumnFilter',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'client_name',
			headerName: 'Client Name',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'value',
			headerName: 'Value Gross',
			filter: 'agNumberColumnFilter',
			valueFormatter: (params: any) => {
				if (params.data.value) {
					return params.data.value.toLocaleString('en-GB', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
					});
				}
			},
			type: 'rightAligned',
			width: 140,
			suppressSizeToFit: true,
		},
		{
			field: 'issued_by',
			headerName: 'Issued By',
			sortable: true,
			resizable: true,
			flex: 1,
		},
		{
			field: 'status',
			headerName: 'Status',
			sortable: true,
			resizable: true,
			cellRenderer: (params: any) => {
				const statusWord = params.getValue();
				if (statusWord) {
					return underscoredToUppercasedWords(statusWord);
				}
			},
			flex: 1,
		},
		{
			headerName: '',
			field: 'delete',
			cellClass: 'icon-grid',
			width: 70,
			sortable: false,
			suppressSizeToFit: true,
			cellRenderer: (params: any) => {
				return (
					permissionTo('delete') &&
					params.getValue() !== false && (
						<FontAwesomeIcon
							icon={faCircleXmark}
							className='icon-danger icon-grid'
							onClick={() => handleDelete(params.data.id)}
						/>
					)
				);
			},
		},
	];

	const calcTotalCols = ['value'];
	const totalRow = function (rowdata: any) {
		let result: any = [{}];

		// Calculate total columns
		calcTotalCols.forEach(function (params) {
			result[0][params] = 0;
			rowdata.forEach(function (line: any) {
				result[0][params] += line[params];
			});
		});

		// Clean-up row output
		result[0]['issue_date'] = false;
		result[0]['expiry_date'] = false;
		result[0]['client_name'] = `Total (${authCtx.tenant.currency_symbol}):`;
		result[0]['delete'] = false;

		return result;
	};

	const loadGridData = () => {
		setGridLoading(true);
		setData([]);
		setEstimateId(null);

		axios.post('/api/jobs/estimates_list', { job_id: jobId, ...getTimezone() }).then((res: any) => {
			setData(res.data);
			setGridLoading(false);
		});
	};

	const handleDelete = (id: string) => {
		if (permissionTo('delete')) {
			presentAlert({
				header: 'Delete Estimate',
				message: 'Are you sure you want to delete this estimate?',
				buttons: [
					{
						text: 'Cancel',
						role: 'cancel',
					},
					{
						text: 'OK',
						role: 'confirm',
						handler: () => {
							handleDeleteGo(id);
						},
					},
				],
			});
		} else {
			showToast('permission');
		}
	};

	const handleDeleteGo = (id: string) => {
		const toastID = toast.loading('Please wait...');

		setEstimateId(null);

		axios
			.delete(`/api/jobs/estimates_list/estimates_card/${id}`)
			.then(() => {
				loadGridData();
				showToast('deleted', null, toastID);
			})
			.catch(() => {
				showToast('error', null, toastID);
			});
	};

	const handleCellClicked = (params: any) => {
		if (params.column.colId !== 'delete' && !params.rowPinned) {
			params.node.setSelected(true);
			setEstimateId(params.data.id);
		}
	};

	const handleCellDoubleClicked = (params: any) => {
		if (params.column.colId !== 'delete' && !params.rowPinned) {
			handleCellClicked(params);
			handleEditEstimate();
		}
	};

	const handleEditEstimate = () => {
		if (permissionTo('update', 'jobs.estimates_list.estimates_card')) {
			const path = `/jobs/estimates_list/estimates_card/${estimateId}`;
			history.push(path);
		} else {
			showToast('permission');
		}
	};

	return (
		<>
			<DataGrid
				cellClickedFunction={handleCellClicked}
				cellDoubleClickedFunction={handleCellDoubleClicked}
				title={'Estimates'}
				cols={colData}
				data={data}
				rowCursorPointer={true}
				totalsRow={totalRow}
				extraFooter={
					<IonRow>
						<IonCol size={'12'} className='p-0 text-right'>
							<IonButton
								color='primary'
								disabled={estimateId === null}
								onClick={() => handleEditEstimate()}
							>
								Edit Estimate
							</IonButton>
						</IonCol>
					</IonRow>
				}
			/>
		</>
	);
};

export default EstimatesIndex;

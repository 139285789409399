import { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ExcelStyle } from '@ag-grid-community/core';
import { DateTime } from 'luxon';

import DataGrid from '../../../../components/DataGrid/DataGrid';
import WorkHistoryModal from '../modals/WorkHistoryModal';
import { numberFormatter } from '../../../../helpers/numbers';

type Props = {
	title: string;
};

const MonthlyAllocationsIndex = (props: Props) => {
	const { workerId }: any = useParams();
	const [startDate, setStartDate] = useState<DateTime | null>(DateTime.now().startOf('month'));
	const [endDate, setEndDate] = useState<DateTime | null>(DateTime.now().endOf('month'));
	const [gridReady, setGridReady] = useState<boolean>(false);
	const gridRef: any = useRef<any>();
	const reportModalRef = useRef<any>(null);
	const columns: Array<any> = [
		{ headerName: 'Jobs and Cost Heads', field: 'job_name', minWidth: 300, flex: 1 },
		{ headerName: 'Job Skill', field: 'job_skill_name', minWidth: 250, flex: 1 },
		{
			headerName: 'Percentage of Time',
			field: 'percentage',
			headerClass: 'ag-right-aligned-header',
			cellClass: 'currency-type',
			type: 'rightAligned',
			valueFormatter: (params: any) => numberFormatter(params.value, 2) +'%',
			flex: 1,
			minWidth: 120
		},
		{
			headerName: 'Time in Minutes',
			field: 'minutes',
			headerClass: 'ag-right-aligned-header',
			cellClass: 'currency-type',
			type: 'rightAligned',
			valueFormatter: (params: any) => params.value,
			flex: 1,
			minWidth: 120
		},
	];
	const excelStyles = useMemo(() => {
		const excelStylesArray: ExcelStyle[] = [
			{
				id: 'date-type',
				dataType: 'DateTime',
				numberFormat: {
					format: 'dd/MM/yyyy',
				},
			},
			{
				id: 'currency-type',
				numberFormat: {
					format: '£#,##0.00',
				},
			},
			{
				id: 'ag-right-aligned-header',
				alignment: {
					horizontal: 'Right',
				},
			},
		];

		return excelStylesArray;
	}, []);

	// De-select the grid rows each time the parameters change
	useEffect(() => {
		if (gridReady && gridRef.current) gridRef.current.deselectAll();
	}, [gridReady, startDate, endDate]);

	const setMonthYear = (month: number, year: number) => {
		let start: DateTime | null = null;
		let end: DateTime | null = null;

		if (month === 13) {
			// Annual
			start = DateTime.now().set({ year }).startOf('year');
			end = start.endOf('year');
		} else if (month === 0) {
			// All time
			start = null;
			end = null;
		} else {
			start = DateTime.now().set({ year, month }).startOf('month');
			end = start.endOf('month');
		}

		setStartDate(start);
		setEndDate(end);
	};

	const handleReportModalClose = () => {
		if (reportModalRef.current) reportModalRef.current.isOpen = false;
	};

	const totalRow = function (rowdata: any) {
		let result: any = [{}];

		// Calculate total columns
		['percentage', 'minutes'].forEach((f: any) => {
			result[0][f] = 0;
			rowdata.forEach((line: any) => (result[0][f] += Number(line[f])));
		});

		result[0]['name'] = 'Totals';
		result[0]['customer_rating'] = null;

		return result;
	};

	return (
		<>
			<DataGrid
				ref={gridRef}
				onGridReady={() => setGridReady(true)}
				useSearch={false}
				title={props.title}
				cols={columns}
				dataSource={{
					url: gridReady ? '/api/workers/workers_list/worker_card/monthly_allocations' : '',
					method: 'POST',
					data: {
						worker_id: workerId,
						start: startDate,
						end: endDate,
					},
				}}
				totalsRow={totalRow}
				rowCursorPointer={true}
				excelStyles={excelStyles}
				exportExcelButton={true}
				filterButtons={true}
				filterOptions={{
					displayCurrentMonth: setMonthYear,
					initialLoad: false,
					defaultAnnual: false,
					displayAnnual: false,
					displayAllTime: false,
				}}
			/>

			<WorkHistoryModal ref={reportModalRef} onClose={handleReportModalClose} />
		</>
	);
};

export default MonthlyAllocationsIndex;
